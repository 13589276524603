import React, { Component } from 'react'
import { Link } from 'gatsby'
import '../components/bootstrap-marketing/startup';
import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import Helmet from 'react-helmet';

import Stage from '../components/stage';

import Layout from '../components/BSLayout';
import MobileNavbar from '../components/MobileNavbar';
import DesktopNavbar from '../components/DesktopNavbar';
import FooterSection from '../components/Footer';


import phoneImage from '../images/app-iphone.png';
import paymentsImage from '../images/booking_payment_sample.png';
import adminImage from '../images/booking_admin_sample.png';

const PrimaryCTA = () => (
  <div className="block-xs-middle pb-5">
    <div className="container">
      <div className="row">
        <div className="col-sm-10 col-lg-8">
          <h1 className="block-titleData frequency">Next generation booking experience</h1>
          <p className="lead mb-4">Seamlessly share facilities in your community.</p>
          <Link to="/contact" className="btn btn-primary btn-lg">Speak with us</Link>
        </div>
      </div>
    </div>
  </div>
)


const Feature = (props) => (
      <div className="col-sm-6 mb-3">
        <h5>{props.header} {props.coming_soon && (<span className={`badge badge-${props.badge_type}`}>Coming Soon</span>)}</h5>
        <p>{props.desc}</p>
      </div>
);

const bookingFeatures = [
    {
        header: "Add ons",
        desc: "We support configurable add ons to help with charging and organisation of additional services. (e.g. Cleaning)"
    },
    {
        header: "Schedules",
        desc: "Define schedules to allow your amenities to only be booked during certain times and days."
    },
    {
        header: "Minimum notice",
        desc: "Define the minimum number of work hours notice required to make a booking."
    }
];
const BookingSection = (props) => (
<div className="block block-secondary app-iphone-block">
  <div className="container">
    <div className="row app-align-center">

      <div className="col-sm-5 d-none d-md-block">
        <img className="app-iphone" src={phoneImage} alt="booking screenshot on mobile" style={{"width": "100%"}} />
      </div>
      <div className="col-md-6 col-sm-12 ml-auto">
        <h6 className="text-muted text-uppercase">Facilities Sharing</h6>
        <h2>Allow your residents to seamlessly share facilities.</h2>
        <p className="lead mb-4">Our online application enables seamless sharing, residents can book Facilities right from their mobile phones.</p>
        <div className="row d-none d-md-flex">
            { bookingFeatures.map( f => <Feature key={f.header} {...f} />) }
        </div>
      </div>
    </div>

  </div>
</div>
)

const paymentFeatures = [
    {
        header: "Usage fees",
        desc: "Charge residents for each booking."
    },
    {
        header: "Add on fees",
        desc: "Charge residents for different convience options."
    },
    {
        header: "Take deposits",
        coming_soon: true,
        badge_type: "light",
        desc: "Our system allows you authorise a residents payment method to take deposits without hassle."
    },
    {
        header: "Secure storage",
        desc: "We securely store card details to make future bookings quick and easy."
    },
];

const SectionPayments = () => (
<div className="block block-inverse block-secondary app-iphone-block">
  <div className="container">
    <div className="row app-align-center">

      <div className="col-md-6 col-sm-12 ml-auto">
        <h6 className="text-muted text-uppercase">Payments made easy</h6>
        <h2>Stop chasing up overdue payments with our integrated billing.</h2>
        <p className="lead mb-4">Our booking system offers a convient, easy and secure way to charge for facilities access.</p>
        <div className="row d-none d-md-flex">
            { paymentFeatures.map( f => <Feature key={f.header} {...f} />) }
        </div>
      </div>
      <div className="col-sm-5 d-none d-md-block">
        <img className="app-iphone" src={paymentsImage} alt="payment section screenshot" style={{"width": "100%"}} />
      </div>
    </div>

  </div>
</div>
)

const managementFeatures = [
    {
        header: "Create reservations",
        desc: "Create reservations for facilities, residents can quickly and easily convert to a booking."
    },
    {
        header: "Block out",
        desc: "Block out times to allow for maintenance."
    },
    {
        header: "Staff only",
        desc: "Restrict facilities so that only staff can create bookings."
    },

];

const SectionManagement = (props) => (
<div className="block block-secondary app-iphone-block">
  <div className="container">
    <div className="row app-align-center">

      <div className="col-sm-5 d-none d-md-block">
        <img className="app-iphone" src={adminImage} alt="Manager interface screenshot on desktop" style={{"width": "100%"}} />
      </div>
      <div className="col-md-6 col-sm-12 ml-auto">
        <h6 className="text-muted text-uppercase">Streamlined facilities management</h6>
        <h2>Accept/Deny bookings in a single click.</h2>
        <p className="lead mb-4">Our management interface makes handling bookings an effortless experience.</p>
        <div className="row d-none d-md-flex">
            { managementFeatures.map( f => <Feature key={f.header} {...f} />) }
        </div>
      </div>
    </div>

  </div>
</div>
)



const cssCTAButton = css`
    margin-top: 3rem;
`

const CTASection = () => (
    <div className="block py-5">
      <div className="container text-center">
        <div className="row mb-5 justify-content-center">
          <div className="col-10 col-md-8 col-lg-10">
            <h6 className="text-muted text-uppercase mb-2">Get in touch</h6>
            <h2>Get in touch to see how we can help you.</h2>
            <Link css={cssCTAButton} className="btn btn-primary btn-lg" to="/contact">Speak with us</Link>
          </div>
        </div>
      </div>
    </div>
);


const HeadData = () => (
  <Helmet>
    <title>Apartment facilities sharing made easy - Castellan</title>
    <meta name="description" content="Help your apartment community share facilities with our simple and easy booking system" />
    <meta name="keywords" content="multi-family residential, apartment, condominium, facilities booking, amenities booking, room booking" />
  </Helmet>
)

const cssNavbar = css`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    font-weight: 500;
    letter-spacing: .05em;
`
const cssCTABackground = css`
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: #000;
`
/*
                 style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    opacity:0.6,
                    background: "#000"
                  }}
 */

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }
  navToggle = () => {
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen});
  }

  render() {
    const { data} = this.props;
    const { menuOpen } = this.state;
    return (
        <Layout>
          <HeadData />
          <div>
            <MobileNavbar />
            <Stage isOpen={menuOpen}>
              <div className="block block-inverse block-fill-height app-header" >
                <div css={cssNavbar} >
                <DesktopNavbar onNavToggle={this.navToggle} transparent={true} />
                </div>
                <Img 
                fluid={data.primaryBackground.childImageSharp.fluid} 
        css={cssCTABackground}
                />
                <PrimaryCTA />
              </div>
                <BookingSection />
                <SectionPayments />
                <SectionManagement />
        {/*
              <SectionManagerSell />
              <SectionResidentSell />
              <TestimonialSection background={data.testimonialBackground} />
              <CurrentCommunitiesSection communities={communities} />
              */}
              <CTASection />
              <FooterSection />
            </Stage>
          </div>
        </Layout>
    );
};
}

export const query = graphql`
    query {
      primaryBackground: file(relativePath: { eq: "cinema_room.jpg" }) {
        childImageSharp {
          fluid(maxWidth:3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
`

export default IndexPage

